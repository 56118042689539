@import 'reset.scss';
@import 'mixins.scss';
@import 'variables.scss';

* {
  box-sizing: border-box;
}

html {
  overflow: hidden;
  background-color: $black;
  font-size: 62.5%;
}

body {
  font-size: 1.4rem;
  color: $white;
  overflow: hidden;
  background-color: $black;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

nav {
  display: none;
  position: fixed;
  bottom: 0;
  background-color: $black;
  width: 100vw;
  height: 7vh;
  z-index: 1000;
  justify-content: space-between;
  align-items: center;
  font-size: 2rem;
  box-shadow: 0px 7.5px 20px grey;
  @media (max-width: $mobile) {
    display: flex;
  }

  .infoNavbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 65%;
    height: 100%;
    padding-left: 1rem;
    transition: min-width 2s;

    &.inView {
      min-width: 95%;
      .details-icon {
        transform: rotate(315deg);
      }
    }

    .details-icon {
      transition: transform 2s;
      position: relative;
      width: 1.4rem;
      height: 1.4rem;
      transform-origin: center;
      &::before,
      &::after {
        display: block;
        content: '';
        width: 1.4rem;
        height: 0.2rem;
        position: absolute;
        top: 50%;
        background: $white;
      }
      &::after {
        transform: rotate(90deg);
      }
    }
  }
  .arrowsContainer {
    display: flex;
    margin-right: 1rem;
    svg {
      transition: all 0.5s;
      fill: $white;
      &:first-child {
        margin-right: 2rem;
      }
      &.disabled {
        fill: grey;
      }
    }
  }
}

.infoContainer {
  z-index: 10;
  position: fixed;
  font-size: 2rem;
  pointer-events: none;

  @media (max-width: $mobile) {
    display: flex;
    flex-direction: column;
    @include size(100vw, 93vh);
    background: $black;
    pointer-events: initial;
    padding: 1.5rem;
    overflow-y: scroll;
    transform: translateY(100vh);
    transition: transform 1s;
    &.open {
      transform: none;
    }
  }
  @media (max-width: $tablet) {
    font-size: 1.6rem;
  }

  h2 {
    font-size: 5rem;
    font-weight: bold;
    margin-bottom: 5rem;
    @media (max-width: $tablet) {
      font-size: 4.5rem;
    }
  }
  h3 {
    font-weight: bold;
  }
  ul {
    margin-top: 1rem;
    li:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  .info {
    position: fixed;
    top: 4rem;
    left: 4rem;
    width: 32rem;
    & > h3 {
      margin-bottom: 3rem;
    }
    &.inside {
      transform: translate(50%, 50%);
      transition: transform 1s;
    }

    @media (max-width: $mobile) {
      position: initial;
      top: 0;
      left: 0;
      width: initial;
      margin-bottom: 3rem;
    }
  }

  .description {
    position: fixed;
    bottom: 4rem;
    right: 4rem;
    width: 32rem;
    & > h3 {
      margin-bottom: 3rem;
      @media (max-width: $mobile) {
        margin-bottom: 1rem;
      }
    }

    @media (max-width: $tablet) {
      width: 24rem;
    }

    @media (max-width: $mobile) {
      position: initial;
      top: 0;
      left: 0;
      width: initial;
      padding-bottom: 120px;
    }
  }
}

.viewContainer {
  @include size;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $black;
  top: 0;
  @media (max-width: $mobile) {
    @include size(100vw, 93vh);
  }

  &::after,
  &::before {
    display: block;
    content: '';
    width: 100vw;
    height: 100vh;
    background-color: $black;
    position: fixed;
    top: 0;
    z-index: 1;
    transition: transform 0.5s;
  }
  &::after {
    clip-path: polygon(100% 0, 0% 100%, 0 0);
    transform: translate(-50%, -50%);
  }
  &::before {
    clip-path: polygon(100% 0, 0% 100%, 100% 100%);
    transform: translate(50%, 50%);
  }
  &.transition {
    &::after,
    &::before {
      transform: translate(0);
    }
  }

  .view {
    display: block;
    @include size(39%, 10%);
    background-color: $white;
    transform: rotate(-45deg);
    transition: all 0.5s, margin 0.4s;
    &:not(:last-child) {
      margin-bottom: 4.7rem;
      @media (max-width: $mobile) {
        margin-bottom: 3.7rem;
      }
    }
    &.reset {
      transform: rotate(0deg);
    }
    &.onView {
      transform: rotate(-45deg) scale(1.2);
    }
    &.clickView {
      transform: rotate(0deg) scale(5);
      width: 100vw;
      margin: 0;
    }
    @media (max-width: $mobile) {
      @include size(100%, 10%);
    }
  }
}

.sectionContainer {
  position: fixed;
  top: 0;
  transition: all 1s;
  pointer-events: none;
  mix-blend-mode: darken;
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
  color: $black;

  section {
    width: 100vw;
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: blur(0.5rem);
    transition: filter 1.5s;
    -webkit-transform: translateZ(0);
    -webkit-perspective: 1000;
    -webkit-backface-visibility: hidden;
    display: flex;
    overflow-x: hidden;
    pointer-events: none;

    @media (max-width: $mobile) {
      flex-direction: column;
    }

    &:nth-child(1) {
      background-image: url('../images/project-1.jpeg');
    }

    &:nth-child(2) {
      background-image: url('../images/project-2.jpeg');
    }

    &:nth-child(3) {
      background-image: url('../images/project-3.jpeg');
    }

    &.active {
      filter: blur(0rem);
    }

    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      min-width: 90vw;
      font-size: 5rem;
      font-weight: bold;
      color: transparent;

      @media (max-width: $mobile) {
        min-height: 60vh;
        justify-content: center;
        align-items: flex-start;
        h2 {
          margin-top: 5rem;
          display: none;
        }
      }
    }
    & .title + .part {
      transform: translateX(20vw);
      @media (max-width: $mobile) {
        transform: translateY(40vh);
      }
    }
    .part {
      display: flex;
      flex-direction: column;
      padding: 15rem;
      padding-top: 18rem;
      min-width: 90rem;
      background-color: $white;
      transition: transform 1s;
      @media (max-width: $mobile) {
        min-width: 100vw;
        padding: 1rem;
        padding-top: 8rem;
      }
      &.resetTransform {
        transform: translateX(0);
      }

      h1 {
        font-size: 4rem;
        font-weight: bold;
        margin-bottom: 2rem;
      }

      p {
        font-size: 1.6rem;
        line-height: 2.3rem;
      }

      a {
        text-decoration: none;
        color: $black;
      }

      &.website-link {
        justify-content: center;
        padding-top: 15rem;
        h1 {
          margin-bottom: 0;
          text-align: center;
        }
      }
    }

    .next {
      cursor: pointer;
      min-width: 20vw;
      min-height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $black;
      @media (max-width: $mobile) {
        min-height: 30vh;
        padding-bottom: 120px;
      }

      h1 {
        color: $white;
        font-size: 4rem;
        font-weight: bold;
      }
    }
  }
}

.changeSectionText {
  transition: all 0.3s;
}

.hide {
  opacity: 0;
  transform: translateY(2rem);
}

.textAppear {
  position: relative;
  overflow: hidden;
  animation: textAppear forwards;
  animation-delay: 1.5s;
  padding: 1rem;
  &::after,
  &::before {
    display: block;
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 1s;
    transform: translateX(-100%);
  }
  &::before {
    background-color: $white;
    z-index: -1;
    animation: whiteBlockAppear 1s forwards;
  }
  &::after {
    background-color: $black;
    animation: blackBlockAppear 2.3s forwards;
    animation-delay: 1s;
  }
}
@keyframes textAppear {
  to {
    color: initial;
  }
}
@keyframes whiteBlockAppear {
  to {
    transform: translateX(0%);
  }
}
@keyframes blackBlockAppear {
  to {
    transform: translateX(200%);
  }
}

.changeText {
  animation: changeText 1s forwards;
}

@keyframes changeText {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  50% {
    opacity: 0;
    transform: translateY(2rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0rem);
  }
}
