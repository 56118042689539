html,
body,
h1,
h2,
h3,
p,
span,
section,
div {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
}

ul,li {
  margin: 0;
}

